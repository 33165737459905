import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"

import moment from "moment"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { ReportDataset } from "shared/report-datasets"
import {
  ChartsDayOfWeekColorMap,
  ChartsDayOfWeekNameMap,
} from "src/charts/shared"

const dow = [1, 2, 3, 4, 5, 6, 7]

function formatData(
  inputData: {
    day_of_week: number
    hour_of_day: number
    nb_reservations: number
    occupancy_rate: number
  }[],
) {
  // Initialize an empty object to hold the formatted data
  let formattedData: { [key: string]: any } = {}

  // Iterate over the input data
  inputData.forEach((item) => {
    // If the hour_of_day is not already in the formattedData object, add it with initial values
    if (!formattedData[item.hour_of_day]) {
      formattedData = {
        ...formattedData,
        [item.hour_of_day]: {
          hour_of_day: item.hour_of_day,
          occupancy_dow_1: 0,
          occupancy_dow_2: 0,
          occupancy_dow_3: 0,
          occupancy_dow_4: 0,
          occupancy_dow_5: 0,
          occupancy_dow_6: 0,
          occupancy_dow_7: 0,
        },
      }
    }

    // Add the number of reservations to the appropriate day of the week
    formattedData[item.hour_of_day][`occupancy_dow_${item.day_of_week}`] = (
      item.occupancy_rate * 100
    ).toFixed(1)
  })

  // Convert the formattedData object to an array
  return Object.values(formattedData)
}

export type OsReportReservationSummaryOccupancyProps = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
  hideRefresh?: boolean
  width?: number
}
export const OsReportReservationSummaryOccupancy: FC<
  OsReportReservationSummaryOccupancyProps
> = ({ ds, isLoading, refresh, hideRefresh, width = 300 }) => {
  const { t } = useTranslation()
  const data = useMemo(() => {
    return formatData(ds?.data || [])
  }, [ds])

  return (
    <Stack direction="column" spacing={4} p={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            {t("reporting.reservationSummaryOccupency.title")}
          </Typography>
          <Typography variant="body2">
            {t("reporting.reservationSummaryOccupency.description")}
          </Typography>
        </Stack>
        {!hideRefresh && (
          <Stack direction="column" spacing={1} alignItems="flex-end">
            <IconButton onClick={refresh} disabled={isLoading}>
              <Refresh />
            </IconButton>
            <Typography variant="caption">
              {ds?.lastUpdated &&
                `${t("lastUpdated")}: ${moment(ds.lastUpdated).format(
                  "YYYY-MM-DD HH:mm:ss",
                )}`}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Box
        sx={{
          width: "1000px",
          height: 300,
        }}
      >
        {data.length > 0 ? (
          <ResponsiveContainer width={width} height="100%">
            <LineChart width={width} height={300} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="hour_of_day" />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Legend />
              {dow.map((d: any) => (
                <Line
                  key={d}
                  type="monotone"
                  dataKey={`occupancy_dow_${d}`}
                  stroke={ChartsDayOfWeekColorMap[d]}
                  activeDot={{ r: 8 }}
                  name={ChartsDayOfWeekNameMap[d]}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              //grey box
              width,
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.100",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{t("noData")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default OsReportReservationSummaryOccupancy
