import { Refresh } from "@mui/icons-material"
import {
  Box,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import moment from "moment"
import { FC, useMemo } from "react"
import { useTranslation } from "react-i18next"
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"
import { ReportDataset } from "shared/report-datasets"
import { DefaultColorMap } from "src/charts/shared"

const formatStringPercent = (s: string) => {
  if (!s) return 0
  const float = parseFloat(s)
  return isNaN(float) ? 0 : parseFloat((float * 100).toFixed(2))
}
const doorSizes = ["small", "medium", "large"]

export type OsReportReservationSummaryOccupancyDoorSizeProps = {
  ds?: ReportDataset<any>
  isLoading: boolean
  refresh: () => void
  hideRefresh?: boolean
  width?: number
}
export const OsReportReservationSummaryOccupancyDoorSize: FC<
  OsReportReservationSummaryOccupancyDoorSizeProps
> = ({ ds, isLoading, refresh, hideRefresh, width = 300 }) => {
  const { t } = useTranslation()
  // multiply by 100 to get percentage
  const data = useMemo(() => {
    if (!ds?.data) return []
    return ds?.data.map((d: any) => {
      return {
        ...d,
        occupancy_rate_door_size_small: formatStringPercent(
          d.occupancy_rate_door_size_small,
        ),
        occupancy_rate_door_size_medium: formatStringPercent(
          d.occupancy_rate_door_size_medium,
        ),
        occupancy_rate_door_size_large: formatStringPercent(
          d.occupancy_rate_door_size_large,
        ),
        occupancy_rate_total: formatStringPercent(d.occupancy_rate_total),
      }
    })
  }, [ds])
  return (
    <Stack direction="column" spacing={4} p={2}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="column" spacing={1}>
          <Typography variant="body1" fontWeight={"bold"}>
            {t("reporting.reservationSummaryOccupencyDoorSize.title")}
          </Typography>
          <Typography variant="body2">
            {t("reporting.reservationSummaryOccupencyDoorSize.description")}
          </Typography>
        </Stack>
        {!hideRefresh && (
          <Stack direction="column" spacing={1} alignItems="flex-end">
            <IconButton onClick={refresh} disabled={isLoading}>
              <Refresh />
            </IconButton>
            <Typography variant="caption">
              {ds?.lastUpdated &&
                `${t("lastUpdated")}: ${moment(ds.lastUpdated).format(
                  "YYYY-MM-DD HH:mm:ss",
                )}`}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Box
        sx={{
          width: "1000px",
          height: 300,
        }}
      >
        {data.length > 0 ? (
          <ResponsiveContainer width={width} height="100%">
            <LineChart width={width} height={300} data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="day" />
              <YAxis domain={[0, "dataMax"]} />
              <Tooltip />
              <Legend />
              {doorSizes.map((d: any, i: number) => (
                <Line
                  key={d}
                  type="monotone"
                  dataKey={`occupancy_rate_door_size_${d}`}
                  stroke={DefaultColorMap[i + 1]}
                  activeDot={{ r: 8 }}
                  name={t(`${d}Door`)}
                />
              ))}
              <Line
                key={"total"}
                type="monotone"
                dataKey={`occupancy_rate_total`}
                stroke={"green"}
                activeDot={{ r: 8 }}
                name={t(`total`)}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <Box
            sx={{
              //grey box
              width,
              height: 300,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              bgcolor: "grey.100",
            }}
          >
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography>{t("noData")}</Typography>
            )}
          </Box>
        )}
      </Box>
    </Stack>
  )
}

export default OsReportReservationSummaryOccupancyDoorSize
